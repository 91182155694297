import React from 'react';
import { Provider } from 'react-redux'; 
import store from './src/store';
import PageBase from './src/components/common/page-base';

export const wrapRootElement = ({element}) => (
	<Provider store={store}>
			{element}
	</Provider>
);

export const wrapPageElement = ({ element, props }) => (
	<PageBase props={ props }>
		{ element }
	</PageBase>
) 

export const shouldUpdateScroll = () => {
	return false;
}

