import { css } from 'styled-components';

/*------------------------------------------
変数管理
--------------------------------------------*/

export const color = {
	txt: "#333333",
	txtLight: "#4D4F54",
	blue: "#2B548A",
	paleBlue: "#77879c",
	red:"#E00000",
	paleGray:"#F7F7F7",
	darkBlue:"#1B2538",
	yellow:"#FFC400",
}

export const size = {
	baseWidth: "85%",
	baseMaxWidth: 900,
	headerH: 75,
	headerH_sp: 60,
	sectionGap_sp: 60,
	pageFirstGap: 90,
	pageLastGap: 118,
}

export const font = {
	base: `"Noto Sans JP", "ヒラギノ角ゴ Pro", "ヒラギノ明朝 Pro", "Oswald", "メイリオ", "游ゴシック", "Noto Serif", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
	en: `"Noto Sans","Oswald",  "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
}

export const breakpoint = {
	sp: 767,
	tb: 1000,
	pc: 1500,
}


export const mixin = {
	spBtnStyleOfSitetop : css`
			width: calc(100% - 4rem*2);
			max-width: 30rem;
			position: absolute;
			bottom: 0;
			left: 50%;
			right: auto;
			transform: translate(-50%, 50%);
	`,
	arrowSettings: css`
			width: 0.8rem;
			height: 0.8rem;
			border-bottom: 2px solid #FFF;
			border-right: 2px solid #FFF;
			transform: rotate(-45deg);
	`,
	baseWidthSet: css`
		width: ${ size.baseWidth };
		max-width: ${ size.baseMaxWidth/10 }rem;
		margin-left: auto;
		margin-right: auto;
	`,
	borderedBg ($deg = "90deg", $color = "#FFF", $borderWidthPx = 3, $gapWidthPx = 2){
		let cssDef = `
			background-image: linear-gradient(${$deg}, ${$color} 0, ${$color} ${$borderWidthPx}px, transparent ${$borderWidthPx}px, transparent ${$borderWidthPx + $gapWidthPx}px, ${$color} ${$borderWidthPx + $gapWidthPx}px, ${$color} ${$borderWidthPx*2 + $gapWidthPx}px, transparent ${$borderWidthPx*2 + $gapWidthPx}px);
	`
		if ( $deg == "90deg"){
			cssDef += `
				background-repeat: repeat-x;
				background-size: ${$borderWidthPx + $gapWidthPx}px 100%;
			`
		} else if ( $deg == "0deg" ){
			cssDef += `
				background-repeat: repeat-y;
				background-size: 100% ${$borderWidthPx + $gapWidthPx}px;
			`
		} else{
			cssDef += `
				background-repeat: repeat;
				background-size: ( ${$borderWidthPx* 2 + $gapWidthPx - 1}px ) ( ${$borderWidthPx*2 + $gapWidthPx - 1}px );
			`
		}
		return css`${ cssDef }`
	},

}


