import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

//svg
import Mds__service from './svg/mds--service.svg';
import Mds__strength from './svg/mds--strength.svg';
import Mds__company from './svg/mds--company.svg';
import Mds__news from './svg/mds--news.svg';
import Mds__contact from './svg/mds--contact.svg';

import { mixin } from '@src/settings';


const Sitemap = (props) => {
	return (
		<Cont className={ props.className }>

			<div className="column">
				<Link to="/services" className="category">
					<p className="en"><Mds__service /></p>
					<p className="ja">事業紹介</p>
				</Link>
				<ul className="list">
					<li className="list__item">
						<Link to="/flow">製作フロー</Link>
					</li>
					<li className="list__item">
						<Link to="/products">製品紹介</Link>
					</li>
					<li className="list__item">
						<Link to="/facility">設備一覧</Link>
					</li>
					<li className="list__item">
						<Link to="/quality">品質・環境への取り組み</Link>
					</li>
				</ul>	
			</div>{/* /.column*/}

			<div className="column">
				<Link to="/strength" className="category">
					<p className="en"><Mds__strength /></p>
					<p className="ja">私たちの強み</p>
				</Link>
				<Link to="/company" className="category">
					<p className="en"><Mds__company /></p>
					<p className="ja">企業情報</p>
				</Link>
				<Link to="/news" className="category">
					<p className="en"><Mds__news /></p>
					<p className="ja">お知らせ</p>
				</Link>
				<Link to="/contact" className="category">
					<p className="en"><Mds__contact /></p>
					<p className="ja">お問い合わせ</p>
				</Link>
			</div>{/* /.column*/}

		</Cont>
	)
}

const Cont = styled.div`
	flex: auto 1 1;
	display: flex;
	justify-content: space-between;

	.column {
		flex: 50% 1 1;
	}
	.column + .column{
		margin-left: 3.5rem;
	}
	.list{
		list-style: none;
		&__item{
			font-size: 1.4rem;
			& > a{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1rem 0;
				&:after{
					content: "";
					display: block;
					${ mixin.arrowSettings }
				}
			}
		}
	}
	.list__item + .list__item{
		border-top: 0.1rem solid transparent;
	}
	.category{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: bold;
		&:after{
			content: "";
			display: block;
			${ mixin.arrowSettings }
			flex: auto 0 0;
		}
		.en{
			flex: auto 0 0;
			margin-right: 1rem;
			& > svg {
				fill: #FFF;
				height: 1.4rem;
				width: auto;
				display: block;
			}
		}
		.ja{
			flex: auto 1 1;
			font-size: 1.2rem;
			font-weight: 300;
		}
	}
	.category + .category{
		margin-top: 1.8rem;
	}
	.category + .list{
		margin-top: 1.8rem;
	}
`

export default Sitemap;
