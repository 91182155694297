import React from 'react';
import { connect } from 'react-redux';
import { Link } from './styles';
import { LogoShape } from './svgs';
import { updateSpMenuState } from '@actions/sp-menu-state';

const Logo = props => (
	<Link to={ props.lang == "ja" ? "/" : "/en" } onClick={() => props.updateSpMenuState(false)}>
		<LogoShape isWhite={ props.isWhite }/>
	</Link>
)

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})
const mapDispatchToProps = dispatch => ({
	updateSpMenuState: (state) => dispatch(updateSpMenuState(state)),
})

export default connect(mapStateToProps,mapDispatchToProps)(Logo);

