import React from 'react';
import { connect } from 'react-redux';
import ListItem from '@components/common/header/sp-menu/list-item';
import { Cont, Box, LangSelecta } from '@components/common/header/sp-menu/styles';

const SpMenu: React.FC = ({ isMenuOpen }) => {

	return (
		<Cont isMenuOpen={ isMenuOpen } >
			<Box>
				<ul>
					<ListItem name="Strength" to="/en/strength" />
					<ListItem name="Services" to="/en/services" >
						<ListItem name="Production Flow" to="/en/flow" isChild={true} />
						<ListItem name="Our Products" to="/en/products" isChild={true} />
						<ListItem name="Quality" to="/en/quality" isChild={true} />
					</ListItem>
					<ListItem name="Facility" to="/en/facility" />
					<ListItem name="Company Information" to="/en/company" >
						<ListItem name="Message" to="/en/message" isChild={true} />
					</ListItem>
					<ListItem name="Contact" to="/en/contact" />
				</ul>
				<LangSelecta/>
			</Box>
		</Cont>
	);
}

const mapStateToProps = state => ({
	isMenuOpen : state.spMenuReducer.isMenuOpen,
})
export default connect(mapStateToProps)(SpMenu);
