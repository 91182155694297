import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";

import { color, breakpoint, size, mixin } from "@src/settings";

import Btn from '@components/common/btn';
import Sitemap from './sitemap';

import Logo from '@components/common/logo';

import Companyname from './svg/companyname.svg';
import Copyright from './svg/copyright.svg';
import FooterLogo_sp from './svg/logo_sp.svg';

const Footer = () => (
	<Cont>
		<div className="container">

			<div className="companyInfo">
				<div className="logoBox">
					<h3 className="logo"><Logo isWhite /></h3>
					<p className="companyName"><Companyname /></p>
				</div>
				<address className="address">Lot 5747, Kidamai Industrial Park,Bukit Angkat,<br/>43000 Kajang, Selangor Darul Ehsan, Malaysia.</address>
				<Btn
					className="btn"
					to="/company"
					isSkelton={ true }
					children="マップでみる"
				/>
			</div>
			<Sitemap className="sitemap" />
		</div>

		<p className="copyright"><Copyright /></p>

	</Cont>
);

const Cont = styled.footer`
	padding: 8rem 0;
	background: ${color.darkBlue};
	color: white;
	.logo{
		width: 6.4rem;
	}
	.container{
		display:flex;
		${ mixin.baseWidthSet }
	}
	.companyInfo {
		flex: 30rem 0 0;
		margin-right: 6rem;
	}
	.address{
		font-size: 1.6rem;
		line-height: 1.6;
		text-align: left;
		font-style: normal;
		margin-bottom: 2rem;
	}
	.companyName{
		margin-top: 2.5rem;
		margin-bottom: 3rem;
		svg{
			height:auto;
			width: 19.8rem;
		}
	}
	.btn{
		max-width: 25rem;
	}
	.copyright {
		display: flex;
		justify-content: flex-end;
		${ mixin.baseWidthSet }
		margin-top: 4rem;
		svg {
			height:auto;
			width: 31.4rem;
			display: inline-block;
		}
	}
	${media.lessThan( `${ breakpoint.tb }px` )`
		.sitemap,
		.btn{
			display: none;
		}
		.container{
			display: block;
		}
		.companyInfo{
			width: 100%;
		}
		.logoBox{
			display: flex;
			align-items: center;
			margin-bottom: 2rem;
		}
		.companyName{
			margin: 0;
			margin-left: 2rem;
		}
	.copyright{
		justify-content: center;
		border-top: 1px solid #FFF;
		padding-top: 3rem;
		margin-top: 2rem;
	}
	`}
`;

//export
export default Footer;

