import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { Link } from 'gatsby';
import { useMeasure, usePrevious } from '@components/common/helpers';
import { Title, TitleLink, Viewmore, ChildWrap } from './styles.tsx';;
import { updateSpMenuState } from '@actions/sp-menu-state';

type Props = {

}

const ListItem: React.FC<Props> = memo(({ name, children, to, defaultOpen = false, isChild = false, updateSpMenuState }) => {
	const [isOpen, setOpen] = useState(defaultOpen);
	const previous = usePrevious(isOpen);
	const [bind, { height: viewHeight }] = useMeasure()
	const { height: animatedHeight, opacity: animatedOpacity } = useSpring({
		from: { height: 0, opacity: 0 },
		to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, }
	})
	
	return (
		<li>
			<Title isChild={ isChild }>

				<TitleLink
					to={ to }
					isParent={ children != undefined }
					isChild={ isChild }
					onClick={ () => updateSpMenuState(false) }
				>
					{ name }
				</TitleLink>

				{ children && (
				<Viewmore
					onClick={ () => setOpen(!isOpen) }
					isOpen={ isOpen }
					isChild={ isChild }
					type="button"
				/>
				)}

			</Title>
			<ChildWrap style={{ height: isOpen && previous === isOpen ? 'auto' : animatedHeight }} >
				<animated.ul style={{ animatedOpacity }} { ...bind } children={ children } />
			</ChildWrap>
		</li>
	)
})

const mapStateToProps = state => ({
	isMenuOpen: state.spMenuReducer.isMenuOpen,
})
const mapDispatchToProps = dispatch => ({
	updateSpMenuState: (state) => dispatch(updateSpMenuState(state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);

