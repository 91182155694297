import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import { breakpoint, color, size } from "@src/settings";

export const Cont = styled.header`
	background-color: #FFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${ props => props.winW > breakpoint.sp ? size.headerH/10 : size.headerH_sp/10 }rem;
	position: relative;
	z-index: 30;
	.bg{
		z-index: 0;	
	}
	.logo{
		flex: 6.4rem 0 0;
		margin-left: 2.2rem;
		position: relative;
		z-index: 5;
	}
	.content{
		flex: auto 1 1;
		align-self: stretch;
		display: flex;
		justify-content: space-between;
		align-items: center
	}
	.header__menu ul li .active .header__menu--submenu {
		display: block;
	}

	.menu{	
		flex: auto 1 1;
		align-self: stretch;
		display: flex;
		justify-content: center;
		&__cont{
			align-self: stretch;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__item {
			list-style: none;
			align-self: stretch;
			display: flex;;
			align-items: center;
			padding: 0 1.5rem;
			/*▼これがないと内包しているaタグの範囲にmouseEventの領域が依存してしまうので重要*/
			position: relative;
			a{
				display: flex;
				position: relative;
				svg{
					display: block;
					height: 1.2rem;
					width: auto;
				}
			}
			&.isParent {
				display: flex;
				align-items: center;
				position: relative;
				transform: translateX( 5% );
				.arrow{
					cursor: pointer;
					flex: auto 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					display: block;
					position: relative;
					padding: 0 0.8rem;
					&:before{
						content: "";
						display: block;
						width: 0.8rem;
						height: 0.8rem;
						border-bottom: 2px solid ${ color.blue };
						border-right: 2px solid ${ color.blue };
						transform-origin: center center;
						transform: rotate(45deg);
						}
					}
				}
			&.isActive .arrow:before{
				transform: translateY(30%) rotate(-135deg);
			}

			&__submenu {
				position: absolute;
				top: 9.3rem;
				left: 0;
				right: 0;
				padding: 5.4rem 0;
				z-index: 10;
				background: white;
				display: none;
			}

			&__item.active &__submenu {
				display: block;
			}
		}
	}
	.header__menu > ul > li > a > img {
		height: 1.3rem;
		display: inline-block;
		width: auto;
	}
	.humburger{
		margin-right: 3rem;
	}

	${media.lessThan( `${ breakpoint.tb }px` )`
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		.logo{
			flex: 5rem 0 0;
			margin-left: 1.2rem;	
		}
		.humburger{
			margin-right: 1.8rem;
		}
		.content{
			position: absolute;
			top: 0;
			transform: translateY(-100%);
		}
	`}
`;
