import React from 'react';

export const Ja = props => (
	<svg viewBox="0 0 21 18" { ...props }>
		<path d="M16.7,10.7l-1.8-5.2l-1.8,5.2H16.7z M13.8,2.6h2.1l4.8,13h-2.2l-1.2-3h-5l-1.1,3H9.1L13.8,2.6z M0.3,14.1l1.4-1.5c0.4,0.7,1.2,1.2,2,1.2c1,0,1.7-0.7,1.7-2.2V2.7h2.2v9c0.1,1.1-0.3,2.3-1.1,3.1c-0.8,0.7-1.8,1.1-2.8,1C2.4,15.8,1.1,15.2,0.3,14.1z" />
	</svg>
)

export const En = props => (
	<svg viewBox="0 0 21 18" { ...props }>
		<path d="M11,2.6h2l5.7,8.6V2.6h2.1v12.9H19l-5.9-8.9v8.9H11L11,2.6L11,2.6z M0.2,2.6h8.2v1.9h-6V8h5.3v1.9H2.3v3.7h6.1v1.9H0.2V2.6z"/>
	</svg>
)
