import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { color } from '@src/settings';
import { updateSpMenuState } from '@actions/sp-menu-state';

const Humburger: React.FC = props => (
	<Cont
		className={ props.className }
		onClick={ () => props.updateSpMenuState(!props.isMenuOpen)}
		isMenuOpen={ props.isMenuOpen }
	>
		<div/>
	</Cont>
)

const Cont = styled.div`
	width: 2.8rem;
	height: 2.3rem;
	position: relative;
	&:before,
	&:after{
		content: "";
		display: block;
	}
	&:before,
	&:after,
	& > div{
		width: 100%;
		height: 0.4rem;
		background-color: ${ props => props.isMenuOpen ? "#FFF" : "#000" };
		position: absolute;
		top: 50%;
		left: 50%;
	}
	& > div{
		transform: translate(-50%,-50%);
	}
	&:before{
		transform: translate(-50%,-150%);
		margin-top: -0.5rem;
	}
	&:after{
		transform: translate(-50%, 50%);
		margin-top: 0.5rem;
	}
	${ props => props.isMenuOpen && `
		& > div{
			opacity: 0;
		}
		&:before{
			transform: translate(-50%, -50%) rotate(45deg);
			margin-top: 0;
		}	
		&:after{
			transform: translate(-50%, -50%) rotate(-45deg);
			margin-top: 0;
		}	
	`}
`

const mapStateToProps = (state,props) => ({
	isMenuOpen: state.spMenuReducer.isMenuOpen,
})
const mapDispatchToProps = dispatch => ({
	updateSpMenuState: (state) => dispatch(updateSpMenuState(state)),
})

export default connect(mapStateToProps,mapDispatchToProps)(Humburger);
