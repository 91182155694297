// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-news-post-detail-index-tsx": () => import("./../src/components/news/post-detail/index.tsx" /* webpackChunkName: "component---src-components-news-post-detail-index-tsx" */),
  "component---src-components-en-news-post-detail-index-tsx": () => import("./../src/components/en/news/post-detail/index.tsx" /* webpackChunkName: "component---src-components-en-news-post-detail-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-company-tsx": () => import("./../src/pages/en/company.tsx" /* webpackChunkName: "component---src-pages-en-company-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-facility-tsx": () => import("./../src/pages/en/facility.tsx" /* webpackChunkName: "component---src-pages-en-facility-tsx" */),
  "component---src-pages-en-flow-tsx": () => import("./../src/pages/en/flow.tsx" /* webpackChunkName: "component---src-pages-en-flow-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-message-tsx": () => import("./../src/pages/en/message.tsx" /* webpackChunkName: "component---src-pages-en-message-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-products-tsx": () => import("./../src/pages/en/products.tsx" /* webpackChunkName: "component---src-pages-en-products-tsx" */),
  "component---src-pages-en-quality-tsx": () => import("./../src/pages/en/quality.tsx" /* webpackChunkName: "component---src-pages-en-quality-tsx" */),
  "component---src-pages-en-services-tsx": () => import("./../src/pages/en/services.tsx" /* webpackChunkName: "component---src-pages-en-services-tsx" */),
  "component---src-pages-en-strength-tsx": () => import("./../src/pages/en/strength.tsx" /* webpackChunkName: "component---src-pages-en-strength-tsx" */),
  "component---src-pages-facility-tsx": () => import("./../src/pages/facility.tsx" /* webpackChunkName: "component---src-pages-facility-tsx" */),
  "component---src-pages-flow-tsx": () => import("./../src/pages/flow.tsx" /* webpackChunkName: "component---src-pages-flow-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-tsx": () => import("./../src/pages/message.tsx" /* webpackChunkName: "component---src-pages-message-tsx" */),
  "component---src-pages-news-tsx": () => import("./../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-products-tsx": () => import("./../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-quality-tsx": () => import("./../src/pages/quality.tsx" /* webpackChunkName: "component---src-pages-quality-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-strength-tsx": () => import("./../src/pages/strength.tsx" /* webpackChunkName: "component---src-pages-strength-tsx" */)
}

