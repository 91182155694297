import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTransition, animated, config } from 'react-spring'; 
import Img from 'gatsby-image/withIEPolyfill'

import Sub_menu__flow from './/sub-menu__flow';
import SubMenu_service2 from './subMenu_service2';
import Sub__menu__quality from './sub-menu__quality';
import SVG_services from '@img/svg/common/subnav__mds--services.svg';
import Btn_service_more from '@img/svg/common/btn_service_more.svg';

import { Container, Content, Title, TitleMain, TitleSub, Cap, Btn, Item } from "./styles";

type Props = {
	title_en: React.ReactElement,
	title_ja: string,
	title_desc: string,
	toForCategoryTop: string,
	items: [{
		ja: string,
		en: React.ReactElement,
		img: any,
		to: string,	
	}]
	lang: string,
}

const SubMenu = props => (
	<Container onClick={ props.onClick } >
		<Content>
			<Title>
				<TitleMain>{ <props.title_en/> }</TitleMain>
				<TitleSub>{ props.title_ja }</TitleSub>
				<Cap>{ props.title_desc }</Cap>
				{ props.toForCategoryTop && props.btnTxt && <Btn to={ props.toForCategoryTop }>{ props.btnTxt }</Btn> }
			</Title>
			{ props.items.map((item, index) => (
				<Item to={ item.to } className="item" key={ index }>
					<Img fluid={ item.img.childImageSharp.fluid }/>
					{ props.lang == "ja" && (
						<p>{ item.ja }</p>
					)}
					<p>{ <item.en/> }</p>
				</Item>
			)) }
		</Content>
	</Container>
)

const mapStateToProps = state => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(SubMenu);

