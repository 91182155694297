export default function reducer(
	state={
		language : "ja",
	},
	action){
	switch( action.type ){
		case "UPDATE_LANGUAGE": {
			return { ...state, language: action.language }
		}
	}
	return state
}
