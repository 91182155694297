import React from 'react';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { color, mixin, breakpoint } from '@src/settings';

const fontSettings = css`
	font-size: 1.8rem;
	line-height: 1.3;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		font-size: 1.5rem;
	`}
`;

export const Cont = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
`;

export const Title = styled.p`
	background-color: #FFF;
	color: ${ color.blue };
	padding: 0.6rem 1rem 1rem;
	${ fontSettings }
`
export const DammyTxt = styled.p`
	display: block;
	padding-top: 0.6rem;
	padding-left: 2rem;
	height: 100%;
	${ fontSettings }
	color: #FFF;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #FFF;
	border-left: none;
`;

export const SelectBox = styled.div`
	align-self: stretch;
	position: relative;
	&:after{
		content: "";
		display: block;
		${ mixin.arrowSettings }
		position: absolute;
		top: 50%;
		right: 2rem;
		transform: translateY(-60%) rotate(45deg);
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		flex-grow: 1;
	`}
`;

export const Select = styled.select`
	opacity: 0;
	height: 100%;
	width: 100%;
	${ fontSettings }
	padding: 0 5rem 0 0;
`;
