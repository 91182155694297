import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Triangle from '@components/common/triangle';

type Props = {
	baseOn: "left" | "right",
	winSize: Array<number>,
	ratio: number,
	offset: number,
	fill: string,
	style: object,
}

const Motif: React.FC<Props> = props => (
	<Cont
		baseOn={ props.baseOn }
		winSize={ props.winSize }
		ratio={ props.ratio }
		offset={ props.offset }
	>
		<Triangle
			style={ props.style }
			baseOn={ props.baseOn }
			fill={ props.fill }	
		/>
	</Cont>
)

const Cont = styled.div`
	position: absolute;
	will-change: transform;
	background-repeat: no-repeat no-repeat;
	width: ${ props => props.winSize[0]*props.ratio }px;
	top: ${ props => props.winSize[0]*props.offset }px;
	${ props => props.baseOn == 'right' && "right: 0;" }
	${ props => props.baseOn == 'left' && "left: 0;" }
`

const mapStateToProps = (state) => ({
	winSize: state.winsizeReducer.winSize,
})

export default connect(mapStateToProps)(Motif);
