export default function reducer(
	state={
		winSize: [0,0],
	},
	action){
	switch( action.type ){
		case "UPDATE_WIN_SIZE": {
			return { ...state, winSize: action.size }
		}
	}
	return state
}
