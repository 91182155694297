import { applyMiddleware, createStore, compose } from "redux"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"

import reducer from "./reducers"

const middleware = applyMiddleware( thunk, createLogger() )

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export default createStore(
	reducer,
	composeEnhancers(
		middleware,
));

