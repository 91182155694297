import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { animated } from 'react-spring';
import { color, size, mixin } from '@src/settings';

const Title = styled.h3`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	border-bottom: 1px solid ${ props => props.isChild ? color.blue : "#FFF"};
`

type titleListProps = { isParent:boolean, isChild: boolean }

const TitleLink = styled(
	({ isParent, isChild, ...props }:titleListProps ) =>  <Link {...props} />
)`
	flex: auto 1 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2.5rem 1.8rem;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.3;
	color: ${ props => props.isChild ? color.blue : "#FFF" };
	${ props => props.isParent == false && `
	&:after{
		content: "";
		display: block;
		${ mixin.arrowSettings }
		${ props.isChild && `border-color: ${ color.blue }` };
		margin-right: 0.8rem;
	}
	`}
`

const ChildWrap = styled( animated.div )`
	will-change: height;
	overflow: hidden;
	background-color: #FFF;
`

const Viewmore = styled.button`
	flex: 5rem 0 0;
	align-self: stretch;
	position: relative;
	outline: none;
	&:before,
	&:after{
		content: "";
		display: block;
		height: 2px;
		width: 1.4rem;
		background-color: #FFF;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		transition: transform 0.2s linear;
	}
	${ props => props.isOpen == false && `
		&:after{
			transform: translate(-50%,-50%) rotate(90deg);
		}
	`}
`

export { Title, TitleLink, Viewmore, ChildWrap };
