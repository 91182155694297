import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { useLocation } from 'react-use';
import { Cont, Title, SelectBox, DammyTxt, Select } from './styles';

const LangSelecta = props => {
	const { pathname } = useLocation();
	let pageLoc = pathname ? pathname.match(/(^\/en|^)(.*?$)/)[2] : '';
	if(pageLoc.match(/\/recruit|\/news/)	!= null){
		pageLoc = "/";
	}
	return (
		<Cont className={ props.className }>
			<Title>Language</Title>
			<SelectBox>
				<DammyTxt>{ props.lang == 'ja' ? 'Japanese':'English' }</DammyTxt>
				<Select onChange={ e => {
						const suffix = e.target.value == 'ja' ? '' : 'en';
						navigate(suffix + pageLoc);
					}}
				>
					<option
						value="ja"
						selected={ props.lang == 'ja' ? true : false }
						children="Japanese"
					/>
					<option
						value="en"
						selected={ props.lang == "en" ? true : false }
						children="English"
					/>
				</Select>
			</SelectBox>
		</Cont>
	);
}

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(LangSelecta);
