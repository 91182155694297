import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { useSpring, animated, config, interpolate } from 'react-spring';
import Triangle from './motif';
import { breakpoint } from '@src/settings';

type Props = {
	className?: string;
	winSize: Array<number>;
}
const Bg: React.FC<Props> = props => {
	
/* ----------------------------------------------------
* パララックス
* ---------------------------------------------------*/
	const [animProps,setAnimProp] = useSpring(() => ({
		scrollY: 0,
		config: { mass: 2, tensiton: 450, friction: 35 },
	}));

	function debounce(func, wait = 5, immediate = false) {
		let timeout;
		return function() {
			const context = this;
			const args = arguments;
			const later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			const callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	const handleScroll = ():void => {
		const scrollY = window.pageYOffset;
		setAnimProp({...animProps, scrollY: scrollY })
	}
	const calc = (speed) => ( v => `translate3d(0, ${ v/props.winSize[0]*100*speed }px, 0)`)

	useEffect(() => {
		window.addEventListener('scroll', debounce(handleScroll))
		return () => {
			window.removeEventListener('scroll', debounce(handleScroll))
		};
	},[]);

/* /パララックス
* ---------------------------------------------------*/
	
/* ----------------------------------------------------
* 配置位置を取得して調整
* ---------------------------------------------------*/
	const measureRef = useRef(false);
  const getTopPos = () => measureRef.current && typeof window == "object" ? measureRef.current.getBoundingClientRect().top + window.pageYOffset : 0;
	const [topPos, setTopPos] = useState(getTopPos());
	const handleTopPos = () => setTopPos(getTopPos());

	useEffect(() => {
		handleTopPos();
	},[props.pageState,props.winSize])

/* /配置位置を取得して調整
* ---------------------------------------------------*/

	return (
		<Cont
			winSize={ props.winSize }
			posT = { topPos }
			className={ props.className }
			ref={ measureRef }
		>
			<div className="wrapper">
				{ props.winSize[0] > 1000 ? (
					<>
						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(-4)),}} 
						>
							<Triangle
								baseOn="right"
								fill="#013374"
								ratio={ 0.52 }
								offset={ -0.12 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(2)),}}
						> 
							<Triangle
								baseOn="left"
								fill="#DCE2EA"
								ratio={ 0.5 }
								offset={ 0.45 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(-0.8)),}}
						> 
							<Triangle
								baseOn="right"
								fill="#DCE2EA"
								ratio={ 0.5 }
								offset={ 1.3 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(1.2)),}}
						> 
							<Triangle
								baseOn="left"
								fill="#091F48"
								ratio={ 0.5 }
								offset={ 2 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(-1.2)),}}
						> 
							<Triangle
								baseOn="right"
								fill="#013374"
								ratio={ 0.5 }
								offset={ 3.6 }
							/>
						</animated.div>

					</>
				):(
					<>
						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(0.5)),}}
						> 
							<Triangle
								baseOn="left"
								fill="#013374"
								ratio={ 2 }
								offset={ props.winSize[0] > breakpoint.sp ? -0.6 : 0 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(0.5)),}}
						> 
							<Triangle
								baseOn="right"
								fill="#DCE2EA"
								ratio={ 2 }
								offset={ props.winSize[0] > breakpoint.sp ? 1.25 : 1.9 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(props.winSize[0] > breakpoint.sp ? 0.5 : 0.8)),}}
						> 
							<Triangle
								baseOn="left"
								fill="#091F48"
								ratio={ 1.5 }
								offset={ props.winSize[0] > breakpoint.sp ? 2.7 : 4.2 }
							/>
						</animated.div>

						<animated.div
							style={{ transform: animProps.scrollY.interpolate(calc(-0.5)),}}
						> 
							<Triangle
								baseOn="right"
								fill="#013374"
								ratio={ 1 }
								offset={ props.winSize[0] > breakpoint.sp ? 6.5 : 10.3 }
							/>
						</animated.div>

					</>
				)}
			</div>
		</Cont>
	)	
}

const Cont = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	mix-blend-mode: multiply;
	opacity: 0.84;
	& > .wrapper{
		position: relative;
		top: ${ props => props.posT*-1 }px;
		width: ${ props => props.winSize[0] }px; 
		height: ${ props => props.winSize[1] }px;
	}
`;

const mapStateToProps = (state) => ({
	pageState: state.pageStateReducer.pageState,
	winSize: state.winsizeReducer.winSize,
})

export default connect(mapStateToProps)(Bg);
