import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BtnComponent from '@components/common/btn';
import { size } from "@src/settings";


export const Container = styled.div`
	width: 100%;
	position: absolute;
	top: ${ size.headerH/10 }rem;
	left: 0;
	right: 0;
	z-index: 10;
	padding: 3.5rem 0 4rem;
	background-color: #FFF;
`

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	& > *{
		padding: 0 3.5rem;
	}
	& > * + *{
		border-left: 1px solid #DEDEDE;
	}
`

export const Title = styled.div`
	flex: 25% 0 0;
`

export const TitleMain = styled.p`
`

export const TitleSub = styled.p`
	margin-top: 0.6rem;
	font-size: 1.3rem;
	line-height: 1;
	margin-bottom: 1.8rem;
`

export const Cap = styled.p`
	font-size: 1.4rem;
`

export const Item = styled(Link)`
	flex: 25% 1 1;
	align-self: stretch;
	display: block;
	& > p:first-of-type{
		font-size: 1.4rem;
		font-weight: bold;
		margin: 1.6rem 0 0;
		& > svg{
			height: 1.2rem;
			width: auto;
		}
	}
	& > p:nth-of-type(2){
		& > svg{
			height: 0.8rem;
			width: auto;
			margin-top: 0.5rem;
			fill: #000;
		}
	}
`
export const Btn = styled(BtnComponent)`
	margin-top: 1.5rem;
	background-color: transparent;
	border: 1px solid #C7BDBD;
	color: #000;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	&:after{
		border-right-color: #CCC;
		border-bottom-color: #CCC;
	}
`;



