import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Frame } from './styles';
import { Ja, En } from './svgs';
import { useLocation } from 'react-use';


const LangSwitch = props => {
	const { pathname } = useLocation();
	let pageLoc = pathname ? pathname.match(/(^\/en|^)(.*?$)/)[2] : '';
	if(pageLoc.match(/\/recruit|\/news/)	!= null){
		pageLoc = "/";
	}

	return(
		<Frame>
			<Link to={ `/${ pageLoc }` } className="lang__btn">
				<Ja style={{ fill: props.lang == "ja" ? "#9EB4CF" : "#FFF" }} />
			</Link>
			<span>/</span>
			<Link to={ `/en/${ pageLoc }` }  className="lang__btn">
				<En style={{ fill: props.lang == "en" ? "#9EB4CF" : "#FFF" }} />
			</Link>
		</Frame>
	)
}

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(LangSwitch)
