import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import { Link } from 'gatsby';

import Svg_viewmore from '@img/svg/common/viewmore.svg';
import { color, breakpoint, mixin } from "@src/settings";

interface Props {
	className: string;
	children?: React.ReactElement | string;
	to?: string;
	isSkelton?: boolean;
	onClick?: any;
}
const Btn = (props: Props) => {
	return (
		<Cont
			className={ props.className }
			to={ props.to }
			isSkelton={ props.isSkelton }
			onClick={ props.onClick }
		>
			{ props.children }	
		</Cont>
	)
}

Btn.defaultProps = {
	children: <Svg_viewmore/>,
}

const size = { 
	size_btn_fontsize: "1.4rem",
	size_btn_width:"22rem",
	size_btn_height:"4.2rem",
}

const Cont = styled((props) => {
	if(props.to){
		return (
			<Link
					to={ props.to }
					children={ props.children }
					className={ props.className }
					onClick={ props.onClick }
			/>
		)	
	} else {
		return (
			<button
				children={ props.children }
				className={ props.className }
				onClick={ props.onClick }
				type="button"
				/>
		);
	}
})`
	display: flex;
	justify-content: justify;
	background-color: ${ props => props.isSkelton == true ? "transparent" : color.yellow };
	max-width: 22rem;
	padding: 1.2rem 3.9rem 1.3rem 1.6rem;
	color: #FFF;
	text-align: left;
	line-height: 1.3;
	font-size: 1.4rem;
	position: relative;
	border: ${ props => props.isSkelton == true ? "1px solid #FFF" : "none" };
	cursor: pointer;
	&:after{
		content: "";
		display: block;
		position: absolute;
		${ mixin.arrowSettings }
		border-bottom-color: #FFF;
		border-right-color: #FFF;
		top: 50%;
		right: 1.6rem;
		transform: translateY(-50%) rotate(-45deg);
	}
	& > svg {
		flex: auto 0 0;
		display: block;
		width: auto;
		height: 1.2rem;
	}

${media.lessThan( `${ breakpoint.sp }px` )`
	max-width: inherit;
`}
`;

export default Btn;

