import React from 'react';

type Props = {
	style: object,
	fill: string,
	baseOn: 'right' | 'left',
	children: never,
}

const Triangle: React.FC<Props> = props => (
		<svg
			className={ props.className }
			viewBox="0 0 50 100"
			style={ props.style }
			fill={ props.fill }
		>
		{ props.baseOn == 'right' && <polygon points="0,50 50,0 50,100" /> }
		{ props.baseOn == 'left' && <polygon points="50 50,0 100,0 0" /> }
		</svg>
)

Triangle.defaultProps = {
	baseOn: "left",
}

export default Triangle;
