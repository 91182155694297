import React from 'recact';
import styled from 'styled-components';
import { Link as LinkBase } from 'gatsby';

export const Link = styled(LinkBase)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;
