import styled from 'styled-components';

export const Frame = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	padding: 0 4rem;
	span{
		margin: 0 1rem;
		color: white;
		font-size: 1.9rem;
		line-height: 1;
		transform: translateY(-15%);
	}
	.lang__btn {
		display: block;
		& > svg {
			height: 1.8rem;
			width: auto;
		}
	}
`
