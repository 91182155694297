import { combineReducers } from 'redux'

import pageStateReducer from './page-state-reducer'
import winsizeReducer from './winsize-reducer'
import spMenuReducer from './spmenu-reducer';
import langReducer from './language-reducer';

export default combineReducers({
	pageStateReducer,
	winsizeReducer,
	spMenuReducer,
	langReducer,
})

