export default function reducer(
	state={
		isMenuOpen: false,
	},
	action){
	switch( action.type ){
		case "CHANGE_SP_MENU_STATE": {
			return { ...state, isMenuOpen: action.isMenuOpen }
		}
	}
	return state
}
