import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-use';
import { useTransition, useSpring } from 'react-spring';

import Header from '@components/common/header';
import HeaderEn from '@components/en/common/header';
import Footer from '@components/common/footer';
import FooterEn from '@components/en/common/footer';
import Bg from '@components/common/bg';
import { changeLanguage } from "@actions/language";
import { updatePageState } from "@actions/page-state";
import { Wrapper, PageCont } from './styles';

const PageBase = ({ children, props, lang, updateLang, updatePageState }) => {

	/* ----------------------------------------------------
	* smoothScroll
	* ---------------------------------------------------*/	

	const [, setY] = useSpring(() => ({ y: 0 }));

	let isStopped = false;
	const onWheel = () => {
		isStopped = true;
		window.removeEventListener('wheel', onWheel)
	}
	const scrollToTop = () => {
		window.addEventListener('wheel', onWheel);
		setY({
			y: 0,
			reset: true,
			from: { y: window.scrollY },
			onRest: () => {
				isStopped = false;
				window.removeEventListener('wheel', onWheel)
			},
			onFrame: props => {
				if(!isStopped){
					window.scroll(0,props.y)
				}
			},
			config: { mass: 1, tension: 400, friction: 50 }
		})
	}

	/* smoothScroll
	* ---------------------------------------------------*/
	/* ----------------------------------------------------
	* page transtion
	* ---------------------------------------------------*/
	const wrapperRef = useRef();
	const contRef = useRef();

	const timer = (num,callback) => new Promise(resolve => {
		setTimeout(() => {
			callback && callback();
			resolve()
		}, num)
	})
	const transitions = useTransition( children, props.path, {
		initial: { position: 'relative', opacity: 0 },
		from: { position: 'absolute', opacity: 0 },
		enter: item => async (next, cancel) => {
			await timer(0);
			await next({ position: 'relative', config: { duration: 1 }});//0は効かない
			await timer(0,() => {
				wrapperRef.current.style.height = `${ contRef.current.getBoundingClientRect().height }px`;
			})
			await next({ opacity: 1 })
			await timer(0,() => { wrapperRef.current.style.height = "auto"})
		},
		leave: item => async (next, cancel) => {
			scrollToTop();
			await timer(0,() => {
				wrapperRef.current.style.height = `${ wrapperRef.current.getBoundingClientRect().height }px`;
			})
			await next({ position: 'absolute', opacity: 0})
		},
		config: (item, state) => {
			switch(state){
				case "enter": return { mass: 1, tension: 500, friction: 25 }
				case "leave": return { mass: 1, tension: 500, friction: 25 }
			}
		}
	})
	/* page transtion
	* ---------------------------------------------------*/	

	/* ----------------------------------------------------
	* management of language
	* ---------------------------------------------------*/

	const { pathname } = useLocation();

	useEffect( () => {
			updateLang(pathname.match(/^\/en/) == null ? "ja" : "en");
		},[children]);

	/* management of language
	* ---------------------------------------------------*/

	return (
		<>
			{ lang == "ja" ? <Header /> : <HeaderEn /> }
			<Wrapper ref={ wrapperRef }> 
				<Bg />
				<div ref={ contRef }>
				{ transitions.map(({ item: page, key, props }) => (
					<PageCont
						key={ key }
						style={ props }
						children={ page }
					/>
				)) }
				</div>
			</Wrapper>
			{ lang == "ja" ? <Footer /> : <FooterEn /> }
		</>
	)
}

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
	pageState: state.pageStateReducer.pageState,
})

const mapDispatchToProps = dispatch => ({
	updateLang: (langName) => dispatch(changeLanguage(langName)),
	updatePageState: (pageState) => dispatch(updatePageState(pageState)),
})

export default connect(mapStateToProps,mapDispatchToProps)(PageBase)
