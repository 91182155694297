export default function reducer(
	state={
		pageState : "initial",
	},
	action){
	switch( action.type ){
		case "UPDATE_PAGESTATE": {
			return { ...state, pageState: action.pageState }
		}
	}
	return state
}
