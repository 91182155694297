import React from 'react';

export const LogoShape = props => {
	const color = {
		main: props.isWhite ? "#FFF" : "#013E6E",
		accent: props.isWhite ? "#FFF" : "#D1291A",
	}
	const defaultStyle = {
		width: "100%",
	}
	return (
		<svg viewBox="0 0 257.3 162.3" style={ defaultStyle } { ...props } >
			<path fill={ color.main } d="M234.9,162.3H22.4c-6,0-11.6-2.3-15.9-6.6c-4.2-4.2-6.6-9.9-6.6-15.9V22.4c0-6,2.3-11.6,6.6-15.9
			C10.8,2.3,16.4,0,22.4,0h212.5c6,0,11.6,2.3,15.9,6.6l0,0c4.2,4.2,6.6,9.9,6.6,15.9v117.4C257.3,152.2,247.3,162.3,234.9,162.3z
			 M22.4,8.6c-3.7,0-7.2,1.4-9.8,4.1c-2.6,2.6-4.1,6.1-4.1,9.8v117.4c0,3.7,1.4,7.2,4.1,9.8c2.6,2.6,6.1,4.1,9.8,4.1h212.5
			c7.6,0,13.9-6.2,13.9-13.9V22.4c0-3.7-1.4-7.2-4.1-9.8c-2.6-2.6-6.1-4.1-9.8-4.1H22.4z"/>
		<polygon fill={ color.main } points="183.6,44.9 183.6,58.7 212.5,58.7 212.5,72.6 183.6,72.6 183.6,99.3 169.7,99.3 169.7,31 216.7,31
			216.7,44.9 "/>
		<polygon fill={ color.main } points="91.8,85.4 91.8,99.3 47,99.3 47,31 60.9,31 60.9,85.4 "/>
		<polygon fill={ color.accent } points="57.7,113.2 57.7,117.4 51.3,117.4 51.3,134 47,134 47,117.4 40.6,117.4 40.6,113.2 "/>
		<rect x="143.8" y="121.6" transform="matrix(3.650064e-11 -1 1 3.650064e-11 30.6891 278.0825)" fill={ color.accent } width="21.1" height="4.3"/>
		<path fill={ color.accent } d="M140.4,117.4c-4.7,0-8.6,3.8-8.6,8.6c0,4.7,3.8,8.6,8.6,8.6c4.7,0,8.6-3.8,8.6-8.6
			C149,121.3,145.1,117.4,140.4,117.4z M140.3,130.3c-2.4,0-4.3-1.9-4.3-4.3c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3
			C144.6,128.4,142.7,130.3,140.3,130.3z"/>
		<path fill={ color.accent } d="M168.1,117.4c-4.7,0-8.6,3.8-8.6,8.6c0,4.7,3.8,8.6,8.6,8.6s8.6-3.8,8.6-8.6
			C176.7,121.3,172.9,117.4,168.1,117.4z M168.1,130.3c-2.4,0-4.3-1.9-4.3-4.3c0-2.4,1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3
			C172.4,128.4,170.5,130.3,168.1,130.3z"/>
		<path fill={ color.accent } d="M88.7,128.8l3.3,2.8c-1.6,1.9-3.9,3.1-6.6,3.1c-2.6,0-5-1.2-6.6-3.1c-1.2-1.5-2-3.4-2-5.5s0.8-4,2-5.5
			c1.6-1.9,3.9-3.1,6.6-3.1c2.6,0,5,1.2,6.6,3.1l-3.3,2.8c-0.8-0.9-2-1.5-3.3-1.5c-1.3,0-2.5,0.6-3.3,1.5c-0.6,0.7-1,1.7-1,2.8
			c0,1,0.4,2,1,2.8c0.8,0.9,2,1.5,3.3,1.5C86.7,130.3,87.9,129.7,88.7,128.8z"/>
		<path fill={ color.accent } d="M74.3,126c0-0.4,0-0.9-0.1-1.3c-0.6-4.1-4.2-7.3-8.5-7.3c-4.7,0-8.6,3.8-8.6,8.6c0,4.7,3.8,8.6,8.6,8.6
			c2.6,0,5-1.2,6.6-3.1l-3.3-2.8c-0.8,0.9-2,1.5-3.3,1.5c-1.8,0-3.3-1.1-4-2.7h12.4C74.3,127.1,74.3,126.6,74.3,126z M61.7,124.7
			c0.5-1.7,2.2-3,4.1-3c1.9,0,3.6,1.3,4.1,3H61.7z"/>
		<path fill={ color.accent } d="M109.9,123.9v10.4h-4.7v-8.9c0-2.2-1-3.3-2.8-3.3c-1.8,0-2.9,1.1-2.9,3.3v8.9h-4.7v-22.1h4.7v6.1
			c1.1-0.5,2.3-0.8,3.6-0.8c1.2,0,2.3,0.2,3.3,0.7c0,0,0,0,0,0c0.4,0.2,0.8,0.3,1.1,0.6C109.3,119.9,109.9,121.5,109.9,123.9z"/>
		<path fill={ color.accent } d="M128.8,123.9v10.4h-4.7v-8.9c0-2.2-1-3.3-2.8-3.3c-1.8,0-2.9,1.1-2.9,3.3v8.9h-4.7v-16.8h4.7v1.4
			c0.9-0.7,2.3-1.2,3.6-1.3c1.2-0.1,2.3,0.1,3.3,0.6c0,0,0,0,0,0C127.3,119.1,128.7,120.9,128.8,123.9z"/>
		<path fill={ color.accent } d="M192.2,117.4v1.4c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c-0.7-0.4-1.4-0.7-2.1-0.9c0,0,0,0,0,0
			c-0.7-0.2-1.4-0.3-2.1-0.3c-3.4,0-6.4,2-7.7,4.9c-0.6,1.7-1.1,4.1,0.3,6.7c1.5,2.6,4.3,4.4,7.5,4.4c1.6,0,3.4-0.2,4.8-1.5
			c0,0.7,0,1.5-0.7,2.2c-0.9,0.9-2.9,1.6-5.1,1.2c-1-0.2-1.9-0.4-2.5-0.7l-1.6,3.8c1.8,0.6,3.7,0.9,5.5,0.9c2.7,0,5.4-0.8,6.8-2.2
			c1.3-1.3,2-3.4,2.1-6.2c0-0.1,0-0.2,0-0.3v-13.3H192.2z M191.8,126c-0.3,1.8-1.9,3.2-3.9,3.2s-3.6-1.4-3.9-3.2c0-0.2,0-0.4,0-0.6
			c0-0.2,0-0.4,0.1-0.6c0.3-1.8,2-3.1,3.9-3.1c2,0,3.6,1.3,3.9,3.1c0,0.2,0.1,0.4,0.1,0.6C191.9,125.7,191.9,125.8,191.8,126z"/>
		<path fill={ color.accent } d="M217,117.4l-0.2,0.6l-6.3,16.9c-1.3,3.3-2.6,4.6-5.4,4.6c-1.6,0-2.8-0.4-4-1l1.4-3.5c0.6,0.4,1.4,0.6,2,0.6
			c0.8,0,1.2-0.2,1.6-1.1l-6.5-16.5l-0.2-0.6h5l0.2,0.6l3.7,11.2l3.6-11.2l0.2-0.6H217z"/>
		<path fill={ color.main } d="M152.8,79.7c0,0.2,0,0.3,0,0.5c0,7.4-3.3,13.2-8.6,16.8l-1.5,0.9c-4.1,2.1-8.7,3.3-13.6,3.3
			c-1.7,0-3.4-0.1-5-0.4c-0.9-0.2-5.6-0.7-9.6-2.4c-3.5-1.3-6.8-3.3-9.5-5.8l9.1-10.6c2.9,1.9,6.1,3.4,9.6,4.2
			c1.9,0.4,6.1,1.3,8.7-0.3c0.1,0,0.1-0.1,0.2-0.1c0.4-0.3,0.8-0.6,1.1-0.9c1.3-1.3,2-3,2-4.9c0-0.7-0.1-1.4-0.3-2.1v0
			c-0.4-1.2-1-2.1-1.7-2.7c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.1
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.5-0.3-0.7-0.4c0,0,0,0,0,0c-0.5-0.3-1-0.5-1.4-0.7
			c0,0-0.1,0-0.1-0.1c-1.7-0.8-4-1.7-5.2-2.2l-5.8-2.5l-2.8-1.3c-4.7-2.1-8.3-6.1-9.8-11.1l-0.6-2.1c-0.2-1.3-0.4-2.8-0.4-4.3v-0.2
			c0-5.7,2.1-10.6,5.7-14.2l2.2-1.8c4.7-3.2,10.4-5.1,16.6-5.1c4.3,0,8.4,0.9,12.1,2.6c3.7,1.7,7,4.1,9.6,7l-8.9,10.3
			c-3.4-2.8-7.4-4.8-11.8-5.8c-0.7-0.1-1.5-0.2-2.2-0.2h0c-0.1,0-0.2,0-0.2,0l0,0h-0.1c0,0-0.1,0-0.1,0c-1.9,0-3.6,0.8-4.9,2
			c-1.3,1.3-2,3-2,4.9c0,1.1,0.3,2.1,0.7,3c0.5,0.8,1.6,2.5,4.4,3.7c0,0,0,0,0,0c0.3,0.1,0.6,0.2,0.9,0.4l0.6,0.3l0.3,0.2
			c1.4,0.7,3.1,1.4,5,2.2c3,1.3,6.8,2.8,10.9,5.8C149.5,67.8,152.8,73.4,152.8,79.7z"/>
		</svg>
	)
}

