import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Mds__service from '@components/common/footer/svg/mds--service.svg';
import Mds__strength from '@components/common/footer/svg/mds--strength.svg';
import Mds__company from '@components/common/footer/svg/mds--company.svg';
import Mds__news from '@components/common/footer/svg/mds--news.svg';
import Mds__contact from '@components/common/footer/svg/mds--contact.svg';

import { mixin } from '@src/settings';


const Sitemap = (props) => {
	return (
		<Cont className={ props.className }>

			<div className="column">
				<Link to="/en/services" className="category">
					<p className="en"><Mds__service /></p>
				</Link>
				<ul className="list">
					<li className="list__item">
						<Link to="/en/flow">Production Flow</Link>
					</li>
					<li className="list__item">
						<Link to="/en/products">Our Products</Link>
					</li>
					<li className="list__item">
						<Link to="/en/facility">Facility</Link>
					</li>
					<li className="list__item">
						<Link to="/en/quality">Quality</Link>
					</li>
				</ul>	
			</div>{/* /.column*/}

			<div className="column">
				<Link to="/en/strength" className="category">
					<p className="en"><Mds__strength /></p>
				</Link>
				<Link to="/en/company" className="category">
					<p className="en"><Mds__company /></p>
				</Link>
				<Link to="/en/news" className="category">
					<p className="en"><Mds__news /></p>
				</Link>
				<Link to="/en/contact" className="category">
					<p className="en"><Mds__contact /></p>
				</Link>
			</div>{/* /.column*/}

		</Cont>
	)
}

const Cont = styled.div`
	flex: auto 1 1;
	display: flex;
	justify-content: space-between;

	.column {
		flex: 50% 1 1;
	}
	.column + .column{
		margin-left: 3.5rem;
	}
	.list{
		list-style: none;
		&__item{
			font-size: 1.7rem;
			& > a{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.8rem 0;
				&:after{
					content: "";
					display: block;
					${ mixin.arrowSettings }
				}
			}
		}
	}
	.list__item + .list__item{
		border-top: 0.1rem solid transparent;
	}
	.category{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
		&:after{
			content: "";
			display: block;
			${ mixin.arrowSettings }
			flex: auto 0 0;
		}
		.en{
			flex: auto 0 0;
			& > svg {
				fill: #FFF;
				height: 1.4rem;
				width: auto;
				display: block;
			}
		}
	}
	.category + .category{
		margin-top: 1.8rem;
	}
	.category + .list{
		margin-top: 1.8rem;
	}
`

export default Sitemap;
