import React from 'react';
import { connect } from 'react-redux';
import ListItem from './list-item';
import { Cont, Box, LangSelecta } from './styles';
import styled from 'styled-components';

const SpMenu: React.FC = ({ isMenuOpen, updateSpMenuState }) => {

	return (
		<Cont isMenuOpen={ isMenuOpen } >
			<Box>
				<ul>
					<ListItem name="私たちの強み" to="/strength" />
					<ListItem name="事業紹介" to="/services" >
						<ListItem name="生産フロー" to="/flow" isChild={true} />
						<ListItem name="製品紹介" to="/products" isChild={true} />
						<ListItem name="品質環境への取り組み" to="/quality" isChild={true} />
					</ListItem>
					<ListItem name="設備一覧" to="/facility" />
					<ListItem name="会社概要" to="/company" >
						<ListItem name="代表挨拶" to="/message" isChild={true} />
					</ListItem>
					<ListItem name="お問い合わせ" to="/contact" />
				</ul>
				<LangSelecta/>
			</Box>
		</Cont>
	);
}

const mapStateToProps = state => ({
	isMenuOpen : state.spMenuReducer.isMenuOpen,
})
export default connect(mapStateToProps)(SpMenu);
