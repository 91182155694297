import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { useTransition, animated, config } from 'react-spring';
import classNames from 'classnames';

import SubMenu from './submenu';
import Bg from '../bg';
import Logo from '@components/common/logo';
import Humburger from './humburger';
import SpMenu from './sp-menu';
import LangSwitch from '@components/common/header/lang-switch';

import SVG_strength from '@img/svg/common/menu__strength.svg';
import SVG_services from '@img/svg/common/menu__services.svg';
import SVG_facility from '@img/svg/common/menu__facility.svg';
import SVG_company from '@img/svg/common/menu__company.svg';
import SVG_contact from '@img/svg/common/menu__contact.svg';
import SVG_submenuMdsServices from '@img/svg/common/subnav__mds--services.svg';
import SVG_submenuMdsCompany from '@img/svg/common/subnav__mds--company.svg';
import SVG_submenuFlow from '@img/svg/common/subnav__subtitle--flow.svg';
import SVG_submenuPoducts from '@img/svg/common/subnav__subtitle--products.svg';
import SVG_submenuQuality from '@img/svg/common/subnav__subtitle--quality.svg';
import SVG_submenuCompany from '@img/svg/common/subnav__subtitle--company.svg';
import SVG_submenuMessage from '@img/svg/common/subnav__subtitle--message.svg';

import { Cont } from './styles';

type Props = {
	winSize: Array<number>;
}

const HeaderBase: React.FC<Props> = props => {
	const [ activeSubMenu, setActiveSubMenu ] = useState(false);
	const switchSubMenu = (state:string|false):void => setActiveSubMenu(state);
	const transitions = useTransition( activeSubMenu, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: (item, state) => {
			switch(state){
				case "enter": return { mass: 1, tension: 500, friction: 20 } 
				case "leave": return { mass: 1, tension: 500, friction: 55 }
			}
		}
	});

	return (
		<Cont
			winW = { props.winSize[0] }
			onMouseLeave={ () => switchSubMenu(false)}
		>
			{ props.winSize[0] > 1000 && <Bg className="bg" /> }
			<h1 className="logo"><Logo isWhite={ props.isMenuOpen } /></h1>
			{ props.winSize[0] > 1000 ? (
			<div className="content">
				<div class="menu">
				  <ul className="menu__cont">
						<li
							className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
				  		<Link to="/strength"><SVG_strength/></Link>
				  	</li>
				  	<li
				  		className={ classNames( 'menu__item', 'isParent', { isActive : activeSubMenu == "services" }) }
				  		onMouseEnter={ () => switchSubMenu("services") }
				  	>
				  		<Link to="/services" ><SVG_services /></Link>
				  		<div
				  			className="arrow"
				  			onClick={() => {
				  				const targetState = activeSubMenu == "services" ? false : "services";
				  				switchSubMenu(targetState);
				  			}}
				  		/>
				  	</li>
				  	<li className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
				  		<Link to="/facility"><SVG_facility/></Link>
				  	</li>
				  	<li
				  	 	className={ classNames( 'menu__item', 'isParent', { isActive : activeSubMenu == "company" }) }
				  		onMouseEnter={ () => switchSubMenu("company") }
				  	>
				  		<Link to="/company"><SVG_company /></Link>
				  		<div
				  			className="arrow"
				  			onClick={() => {
				  				const targetState = activeSubMenu == "company" ? false : "company";
				  				switchSubMenu(targetState);
				  			}}
				  		/>
				  	</li>
				  	<li className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
				  		<Link to="/contact"><SVG_contact/></Link>
				  	</li>
				  </ul>{ /*<!-- /.menu__cont --> */ }
				</div>{/* <!-- /.menu --> */}
				<LangSwitch />
			</div>
			) : ( <SpMenu />)}
			{ props.winSize[0] < 1000 && <Humburger className="humburger"/> }

			{ transitions.map(({ item, key, props: transitionProps }) => { switch( item ){
				case "company":
					return (
						<animated.div style={ transitionProps } key={ key }>
							<SubMenu 
								title_en={ SVG_submenuMdsCompany }
								title_ja="会社概要"
								title_desc="LSF Technologyの会社情報についてのご案内です。"
								onClick={ () => switchSubMenu(false) }
								items={[
									{
										img: props.data.companyImg,
										to: "/company",
										ja: "会社概要",
										en: SVG_submenuCompany,
									},
									{
										img: props.data.messageImg,
										to: "/message",
										ja: "代表からの埃拶",
										en: SVG_submenuMessage,
									}
								]}
							/>
						</animated.div>
					);
					break;
					case "services":
						return (
							<animated.div style={ transitionProps } key={ key }>
								<SubMenu 
									title_en={ SVG_submenuMdsServices }
									title_ja="事業紹介"
									title_desc="大手メーカーの一次サプライヤーとして、確かなサービス、新しい技術をご提供します。"
									btnTxt="事業紹介"
									toForCategoryTop="/services"
									onClick={ () => switchSubMenu(false) }
									items={[
										{
											img: props.data.flowImg,
											to: "/flow",
											ja: "製作フロー",
											en: SVG_submenuFlow,
										},
										{
											img: props.data.productsImg,
											to: "/products",
											ja: "製品紹介",
											en: SVG_submenuPoducts,
										},
										{
											img: props.data.qualityImg,
											to: "/quality",
											ja: "品質・環境への取り組み",
											en: SVG_submenuQuality,
										}
									]}
								/>
							</animated.div>
						);
						break;

			} }) }
		</Cont>
	);
}

const Header = props => (
	<StaticQuery
		query={ graphql`
			query header{
				companyImg: file( relativePath: {eq: "common/sub-menu__company_200512.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				messageImg: file( relativePath: {eq: "common/sub-menu__message.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				flowImg: file( relativePath: {eq: "common/sub-menu__flow.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				productsImg: file( relativePath: {eq: "common/sub-menu__products.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				qualityImg: file( relativePath: {eq: "common/sub-menu__quality.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
			}	
		`}
		render={data => (
			<HeaderBase data={ data } { ...props } />	
		)}
	/>
)



const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
	isMenuOpen: state.spMenuReducer.isMenuOpen,
})

export default connect(mapStateToProps)(Header);
