import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import LangSelectaBase from '@components/common/header/sp-menu/lang-selecta';
import { size, color, mixin, breakpoint } from '@src/settings';

export const Cont = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	background-color: ${ color.blue };
	padding-top: ${ size.headerH_sp }px;
	${ props => props.isMenuOpen ? `
		opacity: 1;
		transform: translateY(0);
		transition: opacity 0.3s;
	`:`
		opacity: 0;
		transform: translateY(-100%);
		transition: transform 0s 0.3s, opacity 0.3s;
	`} 
`

export const Box = styled.div`
	margin-top: 2rem;
	border-top: 1px solid #FFF;
	${ mixin.baseWidthSet }	
`
export const LangSelecta = styled(LangSelectaBase)`
	margin-top: 5rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		width: 100%;
	`}
`;
