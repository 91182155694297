import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled(animated.div)`
	position: relative;
	width: 100%;
`;

export const PageCont = styled(animated.div)`
	width: 100%;
	top: 0;
	left: 0;
`;
