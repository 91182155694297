import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { useTransition, animated, config } from 'react-spring';
import classNames from 'classnames';
import { useLocation } from 'react-use';

import SubMenu from '@components/common/header/submenu';
import Bg from '@components/common/bg';
import Logo from '@components/common/logo';
import Humburger from '@components/common/header/humburger';
import SpMenu from '@components/en/common/header/sp-menu';
import LangSwitch from '@components/common/header/lang-switch';

import SVG_strength from '@img/svg/common/menu__strength.svg';
import SVG_services from '@img/svg/common/menu__services.svg';
import SVG_facility from '@img/svg/common/menu__facility.svg';
import SVG_company from '@img/svg/common/menu__company.svg';
import SVG_contact from '@img/svg/common/menu__contact.svg';
import SVG_submenuMdsServices from '@img/svg/common/subnav__mds--services.svg';
import SVG_submenuMdsCompany from '@img/svg/common/subnav__mds--company.svg';
import SVG_submenuFlow from '@img/svg/common/subnav__subtitle--flow.svg';
import SVG_submenuPoducts from '@img/svg/common/subnav__subtitle--products.svg';
import SVG_submenuQuality from '@img/svg/common/subnav__subtitle--quality.svg';
import SVG_submenuCompany from '@img/svg/common/subnav__subtitle--company.svg';
import SVG_submenuMessage from '@img/svg/common/subnav__subtitle--message.svg';

import { Cont } from '@components/common/header/styles';

type Props = {
	winSize: Array<number>;
}

const HeaderBase: React.FC<Props> = props => {
	const [ activeSubMenu, setActiveSubMenu ] = useState(false);
	const switchSubMenu = (state:string|false):void => {
		if(activeSubMenu != state){
			setActiveSubMenu(state)
		}
	}
	const transitions = useTransition( activeSubMenu, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: (item, state) => {
			switch(state){
				case "enter": return { tension: 120, friction: 10 } 
				case "leave": return { tension: 100, friction: 20 } 
			}
		}
	});
	return (
		<Cont
			winW={ props.winSize[0] }
			onMouseLeave={ () => switchSubMenu(false)}
		>
			{ props.winSize[0] > 1000 && <Bg className="bg" /> }
			<h1 className="logo"><Logo isWhite={ props.isMenuOpen } /></h1>
			{ props.winSize[0] > 1000 ? (
			<div className="content">
				<ul className="menu">
					<li className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
						<Link to="/en/strength"><SVG_strength/></Link>
					</li>
					<li
						className={ classNames( 'menu__item', 'isParent', { isActive : activeSubMenu == "services" }) }
						onMouseEnter={ () => switchSubMenu("services") }
					>
						<Link to="/en/services" ><SVG_services /></Link>
						<div
							className="arrow"
							onClick={() => {
								const targetState = activeSubMenu == "services" ? false : "services";
								switchSubMenu(targetState);
							}}
						/>
					</li>
					<li className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
						<Link to="/en/facility"><SVG_facility/></Link>
					</li>
					<li
						className={ classNames( 'menu__item', 'isParent', { isActive : activeSubMenu == "company" }) }
						onMouseEnter={ () => switchSubMenu("company") }
					>
						<Link to="/en/company"><SVG_company /></Link>
						<div
							className="arrow"
							onClick={() => {
								const targetState = activeSubMenu == "company" ? false : "company";
								switchSubMenu(targetState);
							}}
						/>
					</li>
					<li className="menu__item" onMouseEnter={ () => switchSubMenu(false) }>
						<Link to="/en/contact"><SVG_contact/></Link>
					</li>
				</ul>{ /*<!-- /.menu --> */ }
				<LangSwitch />
			</div>
			) : ( <SpMenu />)}
			{ props.winSize[0] < 1000 && <Humburger className="humburger"/> }

			{ transitions.map(({ item, key, props: transitionProps }) => { switch( item ){
				case "company":
					return (
						<animated.div style={ transitionProps } key={ key }>
							<SubMenu 
								onClick={ () => switchSubMenu(false) }
								title_en={ SVG_submenuMdsCompany }
								title_desc="Company information of LSF Technology"
								items={[
									{
										img: props.data.companyImg,
										to: "/en/company",
										en: SVG_submenuCompany,
									},
									{
										img: props.data.messageImg,
										to: "/en/message",
										en: SVG_submenuMessage,
									}
								]}
							/>
						</animated.div>
					);
					break;
					case "services":
						return (
							<animated.div style={ transitionProps } key={ key }>
								<SubMenu 
									onClick={ () => switchSubMenu(false) }
									title_en={ SVG_submenuMdsServices }
									title_desc="Business introduction of LSF Technology."
									btnTxt="Business introduction"
									items={[
										{
											img: props.data.flowImg,
											to: "/en/flow",
											en: SVG_submenuFlow,
										},
										{
											img: props.data.productsImg,
											to: "/en/products",
											en: SVG_submenuPoducts,
										},
										{
											img: props.data.qualityImg,
											to: "/en/quality",
											en: SVG_submenuQuality,
										}
									]}
								/>
							</animated.div>
						);
						break;

			} }) }
		</Cont>
	);
}

const Header = props => (
	<StaticQuery
		query={ graphql`
			query header_en{
				companyImg: file( relativePath: {eq: "common/sub-menu__company_200512.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				messageImg: file( relativePath: {eq: "common/sub-menu__message.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				flowImg: file( relativePath: {eq: "common/sub-menu__flow.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				productsImg: file( relativePath: {eq: "common/sub-menu__products.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
				qualityImg: file( relativePath: {eq: "common/sub-menu__quality.jpg"} ){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}	
				}
			}	
		`}
		render={data => (
			<HeaderBase data={ data } { ...props } />	
		)}
	/>
)



const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
	isMenuOpen: state.spMenuReducer.isMenuOpen,
})

export default connect(mapStateToProps)(Header);
